import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { defaultDescription } from 'data/config';
import { Wrapper, SkillsWrapper, Details, Thumbnail, ExternalUrl } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt={defaultDescription} />
        </Thumbnail>
        <Details theme={theme}>
          <h1>More about me</h1>
          <p>
            Currently active and co-founder of{' '}
            <ExternalUrl href="https://indigo.online" target="_blank" rel="noopener noreferrer">
              indigo
            </ExternalUrl>
            . I'm interested in{' '}
            <ExternalUrl href="https://www.instagram.com/tschann1k/" target="_blank" rel="noopener noreferrer">
              photography
            </ExternalUrl>
            , sailing and exploring the world 🌍.
          </p>
          <Button as={AnchorLink} href="#contact">
            Contract me
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
